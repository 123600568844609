import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 5.3rem 0;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  .center-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 176rem;
    width: 100%;
  }
  .content {
    max-width: 100%;
  }
  .sidebar {
    margin-left: 3rem;
    /* @media only screen and (max-width: 900px) {
      margin-left: 0;
      width: 100%;
    } */
    .alienware-mention {
      background: var(--background);
      border: .1rem solid var(--border);
      box-shadow: 0px 0.3rem 0.6rem rgb(0 0 0 / 16%);
      box-sizing: border-box;
      max-width: 53rem;
      padding: 2.2rem 3rem;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 2rem;
      text-transform: uppercase;
      .prime-gaming {
        display: flex;
        justify-content: center;
        img {
          width: 80%;
        }
      }
      @media only screen and (max-width: 1563px) {
        margin-top: 2rem;
      }
      p {
        font-size: 1.4rem;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 1.2px;
        margin: 1rem 0;
      }
      img {
        max-width: 25rem;
        width: 100%;
      }
    }
    .twitch-embed {
      background-color: #081519;
      box-shadow: 0px 0.3rem 0.6rem rgb(0 0 0 / 16%);
      transform: translateY(0);
      margin-bottom: 2rem;
      max-width: 53rem;
      &.true {
        display: none;
        .close {
          display: none;
        }
      }
      .close {
        color: #000;
        cursor: pointer;
        font-size: 2rem;
        position: absolute;
        right: -2rem;
        top: -2rem;
        z-index: 2;
      }
      iframe {
        border: none;
        display: block;
        margin: 0 auto;
        max-height: calc(53rem * (1080 / 1920));
        max-width: 53rem;
      }
    }
    .flex-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      @media only screen and (max-width:1560px) {
        margin-top: 2rem;
      }
      .c-VerticalPromoCard {
        /* margin-left: 3rem; */
        @media screen and (max-width: 768px) {
          margin: 2.5rem auto 0 auto;
        }
      }
    }
    .c-SocialCard {
      margin: 3rem 0;
      @media only screen and (max-width: 768px) {
        margin: 3rem auto;
      }
    }
  }
  .news-wrapper {
    background: var(--background);
    backdrop-filter:blur(2px);
    -webkit-backdrop-filter:blur(2px);
    border: .1rem solid var(--border);
    box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    margin-top: 3rem; 
    max-width: 100rem;
    width: 100%;
  }
`;

export default PageWrapper;